// Variable overrides
$sidebar-nav-link-hover-bg: rgba(31, 161, 37, 0.8);
$sidebar-nav-link-active-bg: rgba(31, 161, 37, 0.4);
$navbar-hover-color: white;
$sidebar-nav-dropdown-indicator-color:white;
$breadcrumb-divider-color:white;
$breadcrumb-active-color:white;

//$link-color:                theme-color("primary") !default;
$link-decoration:           none !default;
$link-hover-color: #a0ffa0;
$sidebar-nav-link-icon-color:white;
$sidebar-nav-link-active-icon-color:white;

//$navbar-toggler-icon:                 str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
//$navbar-toggler-icon-hover:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-hover-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;

$body-bg: black;