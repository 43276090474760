// Here you can add other styles

.app {
    background: url('assets/img/bg.png');
}

.app-header {
	color: white;
	background-color: rgba(0, 0, 0, 0.4);
}

.breadcrumb-header {
	color: white;
	background-color: rgba(0, 0, 0, 0.1);
}

.app-body .main .breadcrumb {
	color: white;
	background-color: rgba(0, 0, 0, 0.1);
}

.navbar-toggler:hover{
 background-color:rgba(31, 161, 37, 0.8);
 color:white;
}

.navbar-toggler{
 
 color:white;
}

.app-footer {
	color: white;
	background-color: rgba(0, 0, 0, 0.6);
}

.sidebar{
	background-color: rgba(0, 0, 0, 0.6);
}
//.nav-item:hover{
//	background-color: rgba(31, 161, 37, 0.4);
//}

