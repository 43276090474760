// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";


.border-primary {
  //border-image:linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1)) 1 !important; 
  //box-shadow: 0px 0px 25px 5px rgba(0,255,0,.5);
  border-color: #006000 !important;
  border-width:2px;
  
  

}

.card-header {
  background-color:black;
  color:white;
  font-weight: bold;
}

.card-body {
  background-color:rgba(10,10,10,.8);
  color:white;
}

a {
color:#00a000;
}
a :hover{
color:#20a020;
}


.nav-dropdown.open{
border-left: 4px solid green;
border-image-source: linear-gradient(rgba(31, 161, 37,0.8),rgba(0,255,0,0));
border-image-slice: 4;

}

.nav-dropdown.open .nav-link{
padding-left:12px;
}